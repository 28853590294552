<template>
    <div>
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <h4 class="m-2 font-weight-bolder">Roles List</h4>
            <b-table ref="refUserListTable" class="p-3" :items="Roles" :per-page="perPage" responsive :fields="roleTableColumns" primary-key="id" show-empty empty-text="No matching records found">
                <!-- Column: Music -->
                <template #cell(list)="data">
                    <b-media vertical-align="center">
                        <template #aside> </template>
                        <b-link class="font-weight-bolder text-success d-block text-nowrap">
                            {{ data.item.name }}
                        </b-link>
                        <small v-if="data.item.name_ar" class="text-muted">{{ data.item.name_ar }}</small>
                    </b-media>
                </template>
                <template #cell(actions)="data">
                    <b-badge
                        variant="light-info"
                        :to="{
                            name: 'access-level-permissions',
                            params: { id: data.item.id },
                        }"
                    >
                        Assign Permissions
                    </b-badge>
                    <b-badge variant="light-danger" class="ml-1 cursor-pointer" @click="deleteRoleHandler(data.item.id)">
                        <feather-icon icon="XIcon" size="30" class="align-middle" />
                        <!-- Delete -->
                    </b-badge>
                </template>
            </b-table>
        </b-card>
    </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BFormSelect, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination } from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import userStoreModule from "../user/userStoreModule";
import useAccessLevelList from "./useAccessLevelList";
import accessRepository from "../../../abstraction/repository/accessLevelRepository.js";
const repository = new accessRepository();

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
    },
    setup() {
        const {
            perPageOptions,
            Roles,
            pagination,
            perPage,
            sortBy,
            tableColumns,
            currentPage,
            fetchRoleList,
            deletePlaylist,
            roleTableColumns,
            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        } = useAccessLevelList();

        const USER_APP_STORE_MODULE_NAME = "app-user";
        console.log(Roles);
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        });

        const roleOptions = [
            { label: "Admin", value: "admin" },
            { label: "Author", value: "author" },
            { label: "Editor", value: "editor" },
            { label: "Maintainer", value: "maintainer" },
            { label: "Subscriber", value: "subscriber" },
        ];

        const planOptions = [
            { label: "Basic", value: "basic" },
            { label: "Company", value: "company" },
            { label: "Enterprise", value: "enterprise" },
            { label: "Team", value: "team" },
        ];

        const statusOptions = [
            { label: "Pending", value: "pending" },
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inactive" },
        ];

        onMounted(() => {
            fetchRoleList();
        });

        const changePagePlaylist = async (paginate) => {
            await fetchRoleList(paginate);
        };
        const deleteRoleHandler = async (id) => {
            repository.deleteRole(id).then((res) => {
                // location.reload();
                fetchRoleList();
            });
        };
        return {
            Roles,
            pagination,
            // Filter
            avatarText,
            roleOptions,
            planOptions,
            statusOptions,
            perPageOptions,
            changePagePlaylist,
            fetchRoleList,
            perPage,
            currentPage,
            sortBy,
            tableColumns,
            deletePlaylist,
            roleTableColumns,
            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
            deleteRoleHandler,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
    color: $red;
}
</style>
